import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from '../AdditonalContactAddEditFormValidation';
import NavLinks from '../../../NavLinks';
import useOmrData from '../../../state/omr/UseOmrData';

import useContactRelationshipData from '../../../../common/state/contactRelationshipType/UseContactRelationshipTypesData';
import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import useDidMountEffect from '../../../../common/utils/UseDidMountEffect';

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  contactRelationshipTypeId: Constants.DEFAULT_ID,
  contactRelationshipName: '',
  emailAddress: '',
  phoneNumber: '',
  phoneTypeId: Constants.DEFAULT_ID,
  address1: '',
  address2: '',
  city: '',
  stateCode: '',
  stateName: '',
  postalCode: '',
  validatedAddress: null,
  showedAddressSuggestion: false
};

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const useAddtionalContactO15OtherAddEditContact = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { contactRelationshipTypeState } = useContactRelationshipData();
  const [state, setState] = useState({ isInEditMode: false });
  const { personState, savePersonState, isSaved } = useOmrData();
  const [ validateState, setValidateState ] = useState(INITIAL_VALIDATE_STATE);
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    setFormState,
    handleSubmit,
    updateFormState
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setValidateState({
      isValidating: true
    });
    await handleSubmit();
    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });
    updateFormState('showedAddressSuggestion', true);
  };

  function submitFormCallback() {
    const newAdditionalContactInfo = {
      firstName: formState.firstName?.trim(),
      lastName: formState.lastName?.trim(),
      contactRelationshipTypeId: formState.contactRelationshipTypeId,
      contactRelationshipName: formState.contactRelationshipName,
      emailAddress: formState.emailAddress?.trim(),
      phoneNumber: formState.phoneNumber?.trim(),
      phoneTypeId: formState.phoneTypeId,
      address: {
        address1: formState.address1?.trim(),
        address2: formState.address2?.trim(),
        city: formState.city?.trim(),
        stateCode: formState.stateCode,
        stateName: formState.stateName,
        postalCode: formState.postalCode?.trim(),
        countryCode: formState.countryCode || 'USA'
      }
    };

    const index = location?.state?.i !== undefined ? Number.parseInt(location.state?.i) : formState?.index;
    const newPersonContacts = personState.person.personContacts.map((x) => {
      return { ...x, address: { ...x.address } };
    });

    if (isNaN(index) === true || index < 0 || index >= personState.person.personContacts.length) {
      //new contact
      newPersonContacts.push(newAdditionalContactInfo);
    } else {
      //edit existing contact
      newPersonContacts[index] = newAdditionalContactInfo;
    }

    savePersonState({
      ...personState,
      person: {
        ...personState.person,
        personContacts: newPersonContacts
      }
    });
  };


  useEffect(() => {
    if (location?.state?.personContactObj) {
      if (Object.keys(location?.state?.personContactObj).length > 0) {
        const contactRelationshipTypeId = Array.isArray(contactRelationshipTypeState.arrayData) && contactRelationshipTypeState.arrayData.length > 0
          ? contactRelationshipTypeState.arrayData[0].contactRelationshipTypeId
          : Constants.DEFAULT_ID;

        const contactRelationshipName = Array.isArray(contactRelationshipTypeState.arrayData) && contactRelationshipTypeState.arrayData.length > 0
          ? contactRelationshipTypeState.arrayData[0].typeName
          : ' ';

        const index = state?.isInEditMode === true ? state?.editPersonIndex : personState?.person?.personContacts.length;

        setFormState({
          ...formState,
          index: index,
          firstName: location?.state?.personContactObj?.firstName,
          lastName: location?.state?.personContactObj?.lastName,
          contactRelationshipTypeId,
          contactRelationshipName,
          emailAddress: location?.state?.personContactObj?.email,
          phoneNumber: location?.state?.personContactObj?.phoneNumber,
          phoneTypeId: location?.state?.personContactObj?.phoneTypeId,
          address1: location?.state?.personContactObj?.address1,
          address2: location?.state?.personContactObj?.address2,
          city: location?.state?.personContactObj?.city,
          stateName: location?.state?.personContactObj?.stateName,
          stateCode: location?.state?.personContactObj?.stateCode,
          postalCode: location?.state?.personContactObj?.postalCode
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.personContactObj, state?.isInEditMode]);

  useEffect(() => {
    if (location?.state?.i >= 0) {
      setState({ ...state, 
        isInEditMode: true, 
        editPersonIndex: location.state.i });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.i]);


  useEffect(() => {
    const index = Number.parseInt(location.state?.i);
    if (!formState.firstName
      && index >= 0
      && Array.isArray(personState.person?.personContacts)
      && personState.person.personContacts.length >= index + 1) {

      const personContact = personState.person.personContacts[index] || {};
      setFormData({
        ...formState,
        firstName: personContact.firstName || '',
        lastName: personContact.lastName || '',
        contactRelationshipTypeId: personContact.contactRelationshipTypeId || Constants.DEFAULT_ID,
        contactRelationshipName: personContact.contactRelationshipName || '',
        emailAddress: personContact.emailAddress || '',
        phoneNumber: personContact.phoneNumber || '',
        phoneTypeId: personContact.phoneTypeId || Constants.DEFAULT_ID,
        address1: personContact.address?.address1 || '',
        address2: personContact.address?.address2 || '',
        city: personContact.address?.city || '',
        stateCode: personContact.address?.stateCode || '',
        stateName: personContact.address?.stateName || '',
        postalCode: personContact.address?.postalCode || ''
      }, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personState]);

  // do this after the save state completes
  useDidMountEffect(() => {
    if (isSaved === true) {
      document.getElementById('omrHeadingId')?.scrollIntoView();
      navigate(NavLinks.ADDITIONAL_CONTACTS_O15_OTHER, { replace: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved]);

  return {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    onNextButtonClicked: handleSubmitProxy,
    validateState,
    onBackButtonClicked: () => navigate(NavLinks.ADDITIONAL_CONTACTS_O15_OTHER, { replace: true })
  };
};

export default useAddtionalContactO15OtherAddEditContact;